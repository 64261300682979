import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import moment from "moment";
import { IntlContext } from "src/App";
import { URLS } from "src/helpers/constants/url";
import axiosInstance from "src/helpers/context/axiosInstance";
import { createStyles, makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { ReactComponent as Rarrow } from "src/assets/images/icons/arrow-right.svg";
import { ReactComponent as Cart } from "src/assets/images/icons/accordianCart.svg";
import { ReactComponent as Checkmark } from "src/assets/images/icons/checkmark.svg";
import { ReactComponent as ACheckmark } from "src/assets/images/icons/acheckmark.svg";
import CartDialogBox from "../UI/CartDialogBox";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      "@media (max-width:1199px)": {
        width: "1088px",
      },
      "@media (max-width:899px)": {
        width: "888px",
      },
      "@media (max-width:599px)": {
        width: "688px",
      },
    },
  })
);

const Category_details = ({
  test,
  auth,
  categoryLevel2,
  loginPopup,
  cartItem,
  cartChapterItem,
  cartTestItem,
  setCartTestItem,
  subscriptionPackage,
  purchaseOmnibusArray,
  purchaseChapterArray,
  myOrder,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const [loader, setLoader] = useState(false);
  const [cartPopup, setCartPopup] = useState(false);

  const catlevel3 = location?.pathname?.split("/")[2];

  const getCartItem = async () => {
    let payload = {
      orderId: null,
      productForm: [],
      user: auth?.data?._id,
    };
    var CurrentDate = moment().format("YYYY-MM-DD  HH:mm:ss ZZ");
    var NextDate = moment().add(1, "M");
    var ExpireDate = moment(NextDate).format("YYYY-MM-DD  HH:mm:ss ZZ");
    let catData = {
      productForm: { ...test },
      packageName: [subscriptionPackage?.name, catlevel3, categoryLevel2?.name],
      orderDate: CurrentDate,
      expiryDate: ExpireDate,
    };
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS.getCartItem,
        payload
      );
      if (status === 200 && data?.value === true) {
        setLoader(false);
        setLoader(false);
        data?.data?.productForm?.push(catData);
        addCartItemData(data?.data);
      } else if (status === 200 && data?.value === false) {
        getCartItem();
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const addCartItemData = async (payload) => {
    try {
      const { status } = await axiosInstance.post(URLS.addCartItem, payload);
      if (status === 200) {
        let item = payload?.productForm?.map((obj) => {
          return obj?.productForm?._id;
        });
        setCartTestItem(item);
        openPopup();
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const openPopup = () => {
    setCartPopup(true);
  };

  const AddToCartContent = () => {
    return (
      <>
        <Box>
          <Box
            className="flex_content checkmarkIcon"
            sx={{ justifyContent: "flex-start" }}
          >
            <ACheckmark />
            <Typography className="addcart_text" sx={{ ml: "25px" }}>
              {gothe_mile?.cart?.testAdded}
            </Typography>
          </Box>
          <Typography
            className="addcart_title"
            sx={{ textTransform: "uppercase" }}
          >
            {subscriptionPackage?.name +
              "-" +
              catlevel3 +
              "-" +
              categoryLevel2?.name}
            <span style={{ textTransform: "capitalize" }}>
              {"-" + test?.name}
            </span>
          </Typography>
          <Typography variant="subtitle2" className="test_cost">
            {test?.price && "Price: $" + test?.price}
          </Typography>
          <Grid container spacing={{ xs: 2, ms: 3 }}>
            <Grid item size={{ xs: 12, md: 6 }}>
              <Button variant="checkout_btn" href="/cart">
                {gothe_mile?.cart?.cart_checkout}
              </Button>
            </Grid>
            <Grid item size={{ xs: 12, md: 6 }}>
              <Button variant="outline_btn" onClick={() => setCartPopup(false)}>
                {gothe_mile?.cart?.continueShopping}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box className={classes.root}>
        <Box>
          <Grid container className="flex_content">
            <Grid item size={{ xs: 6 }}>
              <Box className="course_block">
                <Checkmark className="checkmarkIcon" />
                <Typography className="test_name">{test?.name}</Typography>
              </Box>
              <Typography className="test_description">
                {test?.description}
              </Typography>
            </Grid>
            {/* <Grid item xs={4}> */}
            {purchaseOmnibusArray?.includes(subscriptionPackage?._id) ||
            purchaseChapterArray?.includes(categoryLevel2?._id) ||
            myOrder?.includes(test?._id) ? (
              <NavLink
                to={`/test-form/${test?.classMarkFormId}/${test?._id}`}
                className="flex_content linkDeco"
              >
                <Typography className="add_chapter">
                  {gothe_mile?.take_test}
                </Typography>
                <Rarrow className="r-arrow" />
              </NavLink>
            ) : cartItem?.includes(subscriptionPackage?._id) ||
              cartChapterItem?.includes(categoryLevel2?._id) ||
              cartTestItem?.includes(test?._id) ? (
              <Box className="price_chapter_details">
                <Typography className="add_chapter_price">
                  {test?.price && "AU $" + test?.price}
                </Typography>
                <NavLink to="/cart" className="flex_content linkDeco">
                  <Typography sx={{ mr: "10px" }} className="add_chapter">
                    {gothe_mile?.alreadyIncart}
                  </Typography>
                  <Rarrow className="r-arrow" />
                </NavLink>
              </Box>
            ) : (
              <>
                <Box className="price_chapter_details">
                  <Typography className="add_chapter_price">
                    {test?.price && "AU $" + test?.price}
                  </Typography>
                  <NavLink
                    to="#"
                    className="flex_content linkDeco"
                    onClick={() => (auth ? getCartItem() : loginPopup())}
                  >
                    <Cart className="cartIcon" />
                    <Typography sx={{ ml: "10px" }} className="add_chapter">
                      {gothe_mile?.addTest_to_cart}
                    </Typography>
                  </NavLink>
                </Box>
              </>
            )}
            {/* </Grid> */}
          </Grid>
        </Box>
      </Box>
      <CartDialogBox
        open={cartPopup}
        handleClose={() => setCartPopup(false)}
        content={<AddToCartContent />}
      />
    </>
  );
};
export default Category_details;
