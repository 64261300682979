import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { createStyles, makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { fetchFromStorage } from "src/helpers/context/Storage";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import emptycart from "src/assets/images/icons/empty-cart.svg";
import PreLoader from "src/component/Loader";
import "./cart.scss";
import Cart from "./cart";
import Checkout from "./checkout";
import { IntlContext } from "src/App";
import Payment from "./payment";
import OrderSummary from "./orderSummery";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiPaper-root": {
        // marginBottom: "10px",
        "&:after": {
          position: "absolute",
          left: "0",
          top: "0px",
          right: "0",
          height: "1px",
          content: "''",
          opacity: "1",
          backgroundColor: "#D4D4D4",
        },
        "&:before": { top: "64px" },
        "&.Mui-expanded": {
          marginBottom: "0px",
        },
      },
      "& .MuiAccordion-root": {},
      "& .MuiAccordionSummary-root": {
        flexDirection: "row-reverse",
        padding: "0px",
        // minHeight: "28px",
        padding: "16px 0",
        "& .MuiAccordionSummary-content": {
          color: "#101010",
          fontSize: "22.5px",
          fontWeight: "500",
          lineHeight: "28px",
          margin: "0px 0",
          paddingLeft: "14px",
          display: "block",
          "@media (max-width:1199px)": {
            fontSize: "20px",
            lineHeight: "26px",
          },
          "@media (max-width:899px)": {
            fontSize: "18px",
            lineHeight: "24px",
          },
          "@media (max-width:599px)": {
            // fontSize: "16px",
            lineHeight: "22px",
          },
          "& .catLevel_name": {
            fontWeight: "500",
            lineHeight: "19px",
            "@media (max-width:1199px)": {
              fontSize: "18px",
              lineHeight: "24px",
            },
            "@media (max-width:899px)": {
              fontSize: "16px",
              lineHeight: "22px",
            },
            "@media (max-width:599px)": {
              fontSize: "14px",
              lineHeight: "20px",
            },
          },
        },
        "&.Mui-expanded": {
          padding: "16px 0 0",
        },
      },
      "& .MuiAccordionSummary-expandIconWrapper": {
        width: "24px",
        height: "24px",
        textAlign: "center",
        lineHeight: "24px",
        display: "block",
        borderRadius: " 50%",
        backgroundColor: "#41A6E9",
        color: "#fff",
        "&.Mui-expanded": {
          transform: "rotate(0deg)",
        },
        "@media (max-width:1199px)": {
          width: "22px",
          height: "22px",
          lineHeight: "22px",
          fontSize: "16px",
        },
        "@media (max-width:899px)": {
          width: "20px",
          height: "20px",
          lineHeight: "20px",
          fontSize: "14px",
        },
        "@media (max-width:599px)": {
          width: "18px",
          height: "18px",
          lineHeight: "18px",
          fontSize: "12px",
        },
      },
      "& .MuiAccordionDetails-root": {
        padding: "45px 0 27px",
        "@media (max-width:1199px)": {
          padding: "40px 0 25px",
        },
        "@media (max-width:899px)": {
          padding: "35px 0 23px",
        },
        "@media (max-width:599px)": {
          padding: "30px 0 20px",
        },
        "@media (max-width:420px)": {
          padding: "22px 0 20px",
        },
      },
      "& .MuiOutlinedInput-root": {
        height: "54px",
        paddingLeft: "0px",
        borderRadius: "10px",
        paddingLeft: "0",
        "@media (max-width:1199px)": {
          fontSize: "16px",
          lineHeight: "20px",
          height: "48px",
        },
        "@media (max-width:899px)": {
          fontSize: "14px",
          lineHeight: "18px",
          height: "42px",
        },
        "@media (max-width:599px)": {
          fontSize: "12px",
          lineHeight: "16px",
        },
      },
    },
    root_c: {
      textAlign: "center",
      margin: "45px 0 27px",
      "& .continue_btn": {
        fontSize: "18px",
        lineHeight: "22px",
        letterSpacing: "0.36px",
        height: "54px",
        maxWidth: "197px",
        width: "100%",
        padding: "16px",
      },
    },
  })
);

const CCPComponent = () => {
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const redux_auth = useSelector((state) => state?.ActionReduce?.authdata);
  const auth = redux_auth ? redux_auth : fetchFromStorage("authdata");
  const [loader, setLoader] = useState(false);
  const [cart, setCart] = useState([]);
  const [cartTotal, setCartTotal] = useState("0.00");
  const [cartTotalAmount, setCartTotalAmount] = useState({});
  const [cartItem, setCartItem] = useState([]);
  const [expanded, setExpanded] = useState("panel0");
  const [isAdressValid, setIsAdreessValid] = useState(false);
  const [address, setAddress] = useState([]);

  const handleChange = (panel) => (e, newExpanded) => {
    if (panel === "panel2") {
      // if (!isAdressValid) {
      return;
      // }
    }
    setExpanded(newExpanded ? panel : false);
  };

  const getCartItem = async (country) => {
    let payload = {
      user: auth?.data?._id,
      gst_state: country,
    };
    setLoader(true);
    try {
      const { data, status } = await axiosInstance.post(
        URLS.getCartItem,
        payload
      );
      if (status === 200) {
        if (data?.value === true) {
          setLoader(false);
          setCart(data?.data);
          setCartItem(data?.data?.productForm);
          setCartTotal(
            data?.data?.productForm?.length > 0
              ? data?.data?.productForm
                  ?.reduce(
                    (sum, data) =>
                      (sum +=
                        (data?.categoryLevel2 &&
                          parseFloat(data?.categoryLevel2?.price)) ||
                        (data?.categoryLevel3 &&
                          parseFloat(data?.categoryLevel3?.price)) ||
                        (data?.productForm &&
                          parseFloat(data?.productForm?.price))),
                    0
                  )
                  ?.toFixed(2)
              : "0.00"
          );
        } else {
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const getCountry = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "https://ipapi.co/json/"
      );
      if (status === 200) {
        setLoader(false);
        getCartItem(data?.country);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getCountry();
  }, [auth?.data?._id]);

  const accordian = [
    {
      label: "Cart",
      icon: "1",
      content: (
        <Cart
          getCartItem={getCartItem}
          cart={cart}
          cartItem={cartItem}
          setCartItem={setCartItem}
          expandNext={setExpanded}
        />
      ),
    },
    {
      label: "Contact & Billing",
      icon: "2",
      content: (
        <Checkout
          setAddress={setAddress}
          address={address}
          onNextStep={setIsAdreessValid}
          expandNext={setExpanded}
        />
      ),
    },
    {
      label: "Payment",
      icon: "3",
      content: (
        <Payment
          cart={cart}
          cartTotalAmount={cartTotalAmount}
          cartItem={cartItem}
          cartTotal={cartTotal}
          address={address}
        />
      ),
    },
  ];

  return (
    <>
      {loader && <PreLoader />}
      <Box className="sub_container">
        <Box className="cart_block_wraper">
          {auth &&
            (cartItem?.length === 0 ? (
              <Box className="empty_cart_block">
                {/* <EmptycartIcon /> */}
                <img src={emptycart} alt="Empty Cart" />
                <Typography className="empty_cart_title">
                  {gothe_mile?.cart?.empty_cart}
                </Typography>
                <Typography className="browse_test">
                  {gothe_mile?.cart?.browse_popular_test}
                </Typography>
                <Button
                  href="/#subscription"
                  variant="browse_blue"
                  className="browse_test_btn"
                >
                  {gothe_mile?.cart?.browse_test}
                </Button>
              </Box>
            ) : (
              <Box className={classes.root}>
                <Grid
                  container
                  spacing={2.25}
                  sx={{ justifyContent: { xs: "center" } }}
                >
                  <Grid item size={{ xs: 12, md: 8, lg: 8 }}>
                    {accordian?.map((item, index) => {
                      return (
                        <>
                          <Accordion
                            key={index}
                            onChange={handleChange(`panel${index}`)}
                            expanded={expanded === `panel${index}`}
                            disableGutters
                            elevation={0}
                          >
                            <AccordionSummary
                              expandIcon={item?.icon}
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              {item?.label}
                            </AccordionSummary>
                            <AccordionDetails>{item?.content}</AccordionDetails>
                          </Accordion>
                        </>
                      );
                    })}
                  </Grid>

                  {/* OREDERSUMMERY TABLE */}
                  <OrderSummary
                    cart={cart}
                    cartTotal={cartTotal}
                    setCartTotalAmount={setCartTotalAmount}
                  />
                </Grid>
              </Box>
            ))}
        </Box>
      </Box>
    </>
  );
};
export default CCPComponent;
