import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  FormHelperText,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { IntlContext } from "src/App";
import { InputTextField } from "../UI/textfield";
import { createStyles, makeStyles, styled } from "@mui/styles";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { fetchFromStorage } from "src/helpers/context/Storage";
import axiosInstance from "src/helpers/context/axiosInstance";
import { URLS } from "src/helpers/constants/url";
import PreLoader from "src/component/Loader";
import MessageBox from "../UI/MessageBox";
import qs from "querystring";
import { Config } from "src/helpers/context/config";
import { ReactComponent as StripeIcon } from "src/assets/images/icons/Stripe.svg";
import { ReactComponent as InfoIcon } from "src/assets/images/icons/infoIcon.svg";
import { PatternFormat } from "react-number-format";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      overflow: "auto",
      "& .field_block": {
        "& .MuiButton-root": {
          "&.Mui-disabled": {
            color: "#fff",
            cursor: "not-allowed",
            pointerEvents: "auto",
          },
        },
      },
      "& .MuiFormHelperText-root": {
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "19px",
        color: "#101010",
        letterSpacing: "0px",
        fontFamily: "'Montserrat', sans-serif",
        margin: "0px",
        paddingBottom: "12px",
        position: "relative",
        "@media (max-width:1199px)": {
          fontSize: "18px",
          lineHeight: "22px",
        },
        "@media (max-width:899px)": {
          fontSize: "16px",
          lineHeight: "20px",
        },
        "@media (max-width:599px)": {
          fontSize: "14px",
          lineHeight: "18px",
        },
        "&.Mui-error": {
          color: "#C01515",
          margin: "6px 0",
          fontSize: "14px",
          lineHeight: "1",
          textTransform: "capitalize",
          padding: "0px",
        },
      },
      "& .date_field": {
        "& input::-webkit-input-placeholder": {
          textTransform: "uppercase",
        },
        "& .MuiOutlinedInput-root": {
          paddingRight: "0px",
        },
        "& .MuiInputAdornment-root": {
          display: "none",
        },
      },
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          padding: "16px 12px!important",
          lineHeight: "19px",
          color: "#707070",
          height: "22px",
          "@media (max-width:1199px)": {
            height: "16px",
          },
          "@media (max-width:899px)": {
            padding: "14px 12px!important",
            height: "14px",
          },
          "@media (max-width:599px)": {
            padding: "12px !important",
            height: "12px",
          },
        },
      },
    },
  })
);

const PaymentSection = ({
  cart,
  cartTotalAmount,
  cartItem,
  cartTotal,
  address,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const gothe_mile = intlContext?.messages;
  const redux_auth = useSelector((state) => state?.ActionReducer?.authdata);
  const auth = redux_auth ? redux_auth : fetchFromStorage("authdata");
  const [loader, setLoader] = useState(false);
  const [paymentSuccess, setpaymentSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invalidUserMessage, setInvalidUserMessage] = useState(false);
  const [country, setCountry] = useState(null);

  const GSTAmount = ((cartTotal * cart?.gst?.split("%")[0]) / 100)?.toFixed(2);
  const orderTotal = (parseFloat(cartTotal) + parseFloat(GSTAmount))?.toFixed(
    2
  );
  const payableAmount =
    Object.keys(cartTotalAmount)?.length > 0
      ? cartTotalAmount?.discount?.toFixed(2)
      : orderTotal;

  const stripeAuthHeader = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${Config?.StripeApikeySK}`,
  };

  const getCountry = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "https://ipapi.co/json/"
      );
      if (status === 200) {
        setLoader(false);
        setCountry(data?.country);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getCountry();
  }, []);

  const invoiceUrlMail = async (order_id, resetForm) => {
    let payload = {
      order: order_id,
      gst_state: country,
    };
    try {
      const { data, status } = await axiosInstance.post(
        URLS?.orderInvoiceViewUrl,
        payload
      );
      if (status === 200 && data?.value === true) {
        let payload = {
          invoiceUrl: data?.data?.invoiceUrl,
          order: order_id,
        };
        try {
          const { data, status } = await axiosInstance.post(
            URLS?.orderInvoiceMail,
            payload
          );
          if (status === 200 && data?.value === true) {
            setLoader(false);
            setpaymentSuccess(true);
            navigate("/thankyou");
            resetForm();
          } else {
            setLoader(false);
          }
        } catch (error) {
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#F2F2F2",
      "&::before": {
        border: "1px solid #B0B0B0",
        borderRadius: "5px",
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F2F2F2",
      // color: '#F2F2F2',
      maxWidth: 470,
      fontSize: "25px",
      border: "1px solid #B0B0B0",
      borderRadius: "5px",
      padding: "24px",
      "@media (max-width:1199px)": {
        maxWidth: 380,
        padding: "23px",
      },
      "@media (max-width:899px)": {
        maxWidth: 320,
        fontSize: "22px",
        padding: "18px",
      },
      "@media (max-width:599px)": {
        maxWidth: 230,
        fontSize: "20px",
        padding: "16px",
      },
    },
  }));

  const metaPayload = (data) => {
    const productData = [];

    data?.forEach((item) => {
      // Add categoryLevel2 (Omnibus) data if available
      if (item?.categoryLevel2) {
        productData.push({
          productId: item?.categoryLevel2?._id,
          productName: item?.categoryLevel2?.name,
          type: "Omnibus",
          // productName: item?.packageName?.join("-"), // for join all the name same as cart list name
        });
      }

      // Add categoryLevel3 (Chapter) data if available
      if (item?.categoryLevel3) {
        productData.push({
          productId: item?.categoryLevel3?._id,
          productName: item?.categoryLevel3?.name,
          type: "Chapter",
          // productName: item?.packageName?.join("-"), // for join all the name same as cart list name
        });
      }

      // Add productForm (Test) data if available
      if (item?.productForm) {
        productData.push({
          productId: item?.productForm?._id,
          productName: item?.productForm?.name,
          type: "Test",
          // productName: `${item?.packageName?.join(" - ")} - ${
          //   item.productForm.name
          // }`, // for join all the name same as cart list name
        });
      }
    });

    return {
      email: cart?.user?.email,
      user: cart?.user?._id,
      discountAmount: cartTotalAmount?.perDiscount?.toFixed(2) || "",
      couponCode: cartTotalAmount?.coupon?.code || "",
      productData: JSON.stringify(
        Array.isArray(productData) && productData?.length > 0 ? productData : []
      ),
    };
  };

  return (
    <>
      {loader && <PreLoader />}
      <MessageBox
        open={paymentSuccess}
        handleClose={() => setpaymentSuccess(!paymentSuccess)}
        content={<Typography>{gothe_mile?.message?.paymentSuccess}</Typography>}
      />
      <MessageBox
        open={invalidUserMessage}
        handleClose={() => setInvalidUserMessage(!invalidUserMessage)}
        content={
          <Typography className="text-danger">{errorMessage}</Typography>
        }
      />

      {/* BILLING ADDRESS */}

      <Box className="field_block">
        <Formik
          enableReinitialize={true}
          initialValues={{
            card_number: "",
            securitycode: "",
            cardExpiryDate: "",
          }}
          validationSchema={
            payableAmount > 0 &&
            Yup.object().shape({
              card_number: Yup.string().required(
                gothe_mile?.validation?.required_message
              ),
              cardExpiryDate: Yup.string()
                .trim()
                .required(gothe_mile?.validation?.required_message),
              securitycode: Yup.string().required(
                gothe_mile?.validation?.required_message
              ),
            })
          }
          onSubmit={async (value, { resetForm }) => {
            if (payableAmount && payableAmount == "0.00") {
              let payload = {
                amount: payableAmount,
                email: auth?.data?.email,
                metaData: metaPayload(cartItem),
                order_save: {
                  address: {
                    city: address?.address?.city,
                    contryCode: address?.address?.contryCode,
                    country: address?.address?.country,
                    line1: address?.address?.line1,
                    line2: address?.address?.line2,
                    postal_code: address?.address?.postal_code,
                    state: address?.address?.state,
                  },
                  cart: cart?._id,
                  contact: address?.contact,
                  contactNumber: address?.contact,
                  coupon: cartTotalAmount?.coupon?._id,
                  email: auth?.data?.email,
                  name: auth?.data?.name,
                  isActive: true,
                  orderDate: moment(new Date()).format("YYYY-MM-DD"),
                  productForm: cartItem,
                  status: address?.status,
                  subTotal: payableAmount,
                  gst_amount: GSTAmount && GSTAmount,
                  user: auth?.data?._id,
                },
              };
              setLoader(true);
              try {
                const { data, status } = await axiosInstance.post(
                  URLS?.withoutPaymentOrder,
                  payload
                );
                if (status === 200 && data?.value === true) {
                  let order_id = data?.data?.order_id;
                  invoiceUrlMail(order_id, resetForm);
                } else {
                  setLoader(false);
                }
              } catch (error) {
                setLoader(false);
              }
            } else {
              let payload = qs.stringify({
                "card[number]": value?.card_number,
                // 'card[exp_month]': parseInt(moment(value?.cardExpiryDate).format("MM")),
                // 'card[exp_year]': parseInt(moment(value?.cardExpiryDate).format("YYYY")),
                // 'card[exp_month]': parseInt(
                //   moment(value?.cardExpiryDate?.split('/')[0]).format('MM')
                // ),
                "card[exp_month]": parseInt(
                  moment(value?.cardExpiryDate, "MM/YYYY").format("MM")
                ),
                "card[exp_year]": parseInt(
                  moment(
                    new Date().getFullYear().toString().slice(0, 2) +
                      value?.cardExpiryDate?.split("/")[1]
                  ).format("YYYY")
                ),
                "card[cvc]": value?.securitycode,
              });
              setLoader(true);
              try {
                const { data, status } = await axiosInstance.post(
                  Config?.StripeApiURL + URLS?.orderTokens,
                  payload,
                  {
                    headers: stripeAuthHeader,
                  }
                );
                if (status === 200) {
                  // if (status === 200 && data?.value === true) {
                  let payload = {
                    amount: payableAmount,
                    email: auth?.data?.email,
                    id: data?.id,
                    order_save: {
                      address: {
                        city: address?.address?.city,
                        contryCode: address?.address?.contryCode,
                        country: address?.address?.country,
                        line1: address?.address?.line1,
                        line2: address?.address?.line2,
                        postal_code: address?.address?.postal_code,
                        state: address?.address?.state,
                      },
                      cart: cart?._id,
                      contact: address?.contact,
                      contactNumber: address?.contact,
                      coupon: cartTotalAmount?.coupon?._id,
                      email: auth?.data?.email,
                      name: auth?.data?.name,
                      orderDate: moment(new Date()).format("YYYY-MM-DD"),
                      productForm: cartItem,
                      status: address?.status,
                      subTotal: payableAmount,
                      gst_amount: GSTAmount && GSTAmount,
                      user: auth?.data?._id,
                    },
                    metaData: metaPayload(cartItem),
                  };
                  try {
                    const { data, status } = await axiosInstance.post(
                      URLS?.orderPayWithStripe,
                      payload
                    );
                    if (status === 200 && data?.value === true) {
                      let order_id = data?.data?.order_id;
                      invoiceUrlMail(order_id, resetForm);
                    } else {
                      setLoader(false);
                      setInvalidUserMessage(true);
                      setErrorMessage(data?.error?.message);
                    }
                  } catch (error) {
                    setLoader(false);
                    setErrorMessage(gothe_mile?.message?.somethingWrongMsg);
                  }
                } else {
                  setLoader(false);
                }
              } catch (error) {
                setLoader(false);
                setInvalidUserMessage(true);
                setErrorMessage(error?.response?.data?.error?.message);
              }
            }
          }}
        >
          {({
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* PAYMENT SECTION */}
              <Box className={classes.root}>
                <Box className="arrow"></Box>

                <Box className="field_block">
                  {payableAmount && payableAmount == "0.00" ? (
                    <Box sx={{ textAlign: "center" }}>
                      <Button
                        type="submit"
                        variant="subscribe_btn"
                        sx={{
                          mt: { xs: "28px", md: "38px", lg: "50px" },
                        }}
                      >
                        {gothe_mile?.subscribe}
                      </Button>
                    </Box>
                  ) : (
                    <Grid container spacing={2.25}>
                      <Grid item size={{ xs: 12 }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <FormHelperText>
                            {gothe_mile?.card_number}
                          </FormHelperText>
                          <HtmlTooltip
                            title={
                              <Typography
                                sx={{ fontSize: "14px", lineHeight: "19.5px" }}
                              >
                                {gothe_mile?.toolTip_content}
                              </Typography>
                            }
                            placement="right"
                            arrow
                          >
                            <Button
                              sx={{
                                minWidth: "12.5px",
                                p: "0",
                                m: "0 0 12px 8px",
                              }}
                            >
                              <InfoIcon />
                            </Button>
                          </HtmlTooltip>
                        </Box>
                        <InputTextField
                          inputProps={{ maxLength: 19 }}
                          fullWidth
                          className="form_field"
                          placeholder={gothe_mile?.card_number}
                          type="text"
                          name="card_number"
                          value={values?.card_number
                            .replace(/[^\dA-Z]/g, "")
                            .replace(/(.{4})/g, "$1 ")
                            .trim()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched?.card_number && errors?.card_number
                          )}
                          helperText={
                            touched?.card_number && errors?.card_number
                          }
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Grid>
                      {/* <Grid item size={{ xs: 12 }}> */}
                      {/* <Grid container> */}
                      <Grid item size={{ xs: 6 }}>
                        <FormHelperText>
                          {gothe_mile?.expiry_date}
                        </FormHelperText>
                        <PatternFormat
                          fullWidth
                          className="form_field date_field"
                          name="cardExpiryDate"
                          value={values?.cardExpiryDate}
                          format="##/##"
                          customInput={InputTextField}
                          placeholder="MM/YY"
                          onChange={handleChange}
                          error={Boolean(
                            touched?.cardExpiryDate && errors?.cardExpiryDate
                          )}
                          helperText={
                            touched?.cardExpiryDate && errors?.cardExpiryDate
                          }
                        />
                      </Grid>
                      <Grid item size={{ xs: 6 }}>
                        <FormHelperText>{gothe_mile?.cvc_code}</FormHelperText>
                        <InputTextField
                          slotProps={{ minLength: 3, maxLength: 4 }}
                          fullWidth
                          className="form_field"
                          placeholder={gothe_mile?.security_code}
                          type="text"
                          name="securitycode"
                          error={Boolean(
                            touched?.securitycode && errors?.securitycode
                          )}
                          helperText={
                            touched?.securitycode && errors?.securitycode
                          }
                          onChange={handleChange}
                          value={values?.securitycode}
                          onBlur={handleBlur}
                          onKeyDown={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Grid>
                      {/* </Grid> */}
                      {/* </Grid> */}
                      <Grid item size={{ xs: 12 }} sx={{ textAlign: "center" }}>
                        <Button
                          type="submit"
                          variant="subscribe_btn"
                          sx={{ mt: { xs: "18px", md: "36px", lg: "50px" } }}
                        >
                          {gothe_mile?.placeOrder}
                        </Button>
                        <Box
                          sx={{
                            py: "27px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottom: "1px solid #D4D4D4",
                          }}
                        >
                          <Typography sx={{ lineHeight: "19px" }}>
                            {gothe_mile?.powered_by}
                          </Typography>
                          <StripeIcon style={{ marginLeft: "12px" }} />
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};
export default PaymentSection;
